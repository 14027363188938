import React from 'react';
import { useState } from 'react';
import Logo from '../Blocks/Logo/Logo.react';

import NavBurger from '../Navburger/Navburger.react';

import {
	StyledHeader,
	HeaderLogoHolder,
	HeaderContactBar,
	HeaderNav,
	MobileControlBar,
} from './Header.styles';

import { WhatsAppButton, PhoneButton } from '../Blocks/ContactBar/ContactBar.react';

import { NavbarMobile } from '../Header/Header.styles';

const Header = ({ className, menu, page, ...props }) => {
	const [open, setOpen] = useState(false);
	const menu1 = menu.slice(0, 4);
	const menu2 = menu.slice(4, 9);
	return (
		<>
			<StyledHeader className={className}>
				<NavbarMobile
					className={className}
					mobile={true}
					page={page}
					open={open}
					menu={menu}
					menuName={'navbarmobile'}
				/>
				<HeaderNav className={className} mobile={false} menu={menu1} page={page} menuName={'header1'} />
				<HeaderLogoHolder className={className} open={open}>
					<Logo />
				</HeaderLogoHolder>
				<HeaderNav className={className} mobile={false} menu={menu2} page={page} menuName={'header2'} />
			</StyledHeader>
			<MobileControlBar setOpen={setOpen} className={className}>
				<WhatsAppButton short />
				<PhoneButton short />
				<NavBurger open={open} setOpen={setOpen} />
			</MobileControlBar>
		</>
	);
};

export default Header;
