import React from 'react';

const Chevron = props => (
	<svg width="18" height="16" viewBox="0 0 18 16" fill="none" {...props}>
		<path
			id="d"
			d="M1.46863 4.45663C2.09347 3.84779 3.10653 3.84779 3.73137 4.45663L9 9.59038L14.2686 4.45663C14.8935 3.84779 15.9065 3.84779 16.5314 4.45663C17.1562 5.06547 17.1562 6.0526 16.5314 6.66144L9 14L1.46863 6.66144C0.84379 6.0526 0.84379 5.06547 1.46863 4.45663Z"
		/>
	</svg>
);

export default Chevron;
