// function that takes a rows object ie {3,4,5} and a galleries array
// and return a new array with an array for each row with
// a quantity of images per row based on the rows object
export const setRows = (rows, gallery) => {
	// set empty array to receive each row as an array
	const galleryRows = [];

	// Calculate the sum of all of the rows
	const rowReduced = rows.reduce((a, c) => a + c);

	// Iterate through each row array value and
	// then slice the number of images equal to the row value
	// and push as an array which will be a gallery row intto the galleryRows array
	let preValue = 0;
	rows.forEach((value, i) => {
		galleryRows.push(gallery.slice(preValue, value + preValue));
		preValue = preValue + value;
	});
	// if there are more images than the rowReduced value then add
	// all remaining images to a last array and push to galleryRows
	gallery.length > rowReduced && galleryRows.push(gallery.slice(rowReduced));
	preValue = 0;
	return galleryRows;
};

// a function that adds the image ratios from each image in a row
export const rowLogicalWidth = row => {
	const v = 0; // the initial value
	return row.reduce((total, rowImage) => total + rowImage.ratio, v);
};

export const widthPercentage = (rowWith, ratio) => {
	return (ratio * 100) / rowWith + '%';
};

export const lightBoxOptions = {
	settings: {
		overlayColor: '#C0CFBF',
		autoplaySpeed: 1500,
		transitionSpeed: 900,
	},
	buttons: {
		backgroundColor: '#E6F3D6',
		iconColor: 'rgba(126, 172, 139, 0.8)',
	},
	caption: {
		captionColor: '#444344',
		captionFontFamily: 'Civita W01 Regular, serif',
		captionFontWeight: '300',
		captionTextTransform: 'uppercase',
	},
	buttons: {
		backgroundColor: 'rgba(230,243,214,0.8)',
		iconColor: '#444344',
		iconPadding: '10px',
		showAutoplayButton: true,
		showCloseButton: true,
		showDownloadButton: false,
		showFullscreenButton: true,
		showNextButton: true,
		showPrevButton: true,
		showThumbnailsButton: false,
		size: '40px',
	},
};
