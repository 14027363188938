import styled from 'styled-components';
import tw, { css } from 'twin.macro';
import { Link } from 'gatsby';

import { Chevron } from 'components/icons';

export const StyledMainMenu = styled.div`
	// set flex for desktop
	${props =>
		props.mobile === false &&
		tw`
      hidden md:flex 
    `}

	${props => !props.open && props.mobile === true && tw`hidden`}

    ${props =>
		props.open &&
		props.mobile === true &&
		tw`
      md:hidden lg:hidden! xl:hidden!
      flex-col
      w-screen
      z-50
      m-0 pt-16
    
      `}
`;

export const NavbarItem = styled(Link)`
	/*can i comment here?*/
	${tw`
    font-serif text-xl md:text-base
    flex 
    items-center
    content-center
    justify-center
    py-5 md:p-0
    md:px-1 lg:px-4 lg:mt-0 
    focus:shadow-outline 
    hover:bg-ultralightgreen hover:text-red-500 
    hover:text-tbrgray
 
    relative
    md:h-10
    hover:shadow-sm
    flex-grow
  `}

	${props =>
		props.selected &&
		tw`
      text-tbrteal

      italic
      border-tbrteal
 border-l-8 md:border-b-2 md:border-l-0 border-r-0 border-t-0 md:pl-3!
    `}
`;

export const NavbarItemSub = styled(NavbarItem)`
	${tw`
  flex-auto
  xs:w-full
  w-1/2
  md:w-full

  my-0! p-4! 
  leading-3 
  justify-center md:justify-start
  text-gray-700 hover:text-tbrteal

  bg-ultralightgreen hover:bg-tbrteal hover:text-gray-100

  `}
	${props =>
		props.selected &&
		tw`
      text-tbrteal

      bg-white  hover:bg-white
      border-tbrteal

      py-5
      border-l-8! border-t-0 border-r-0 border-b-0! pl-3!
      hover:border-tbrteal
      hover:border-l-8 hover:border-t-0 hover:border-r-0 hover:border-b-0 hover:pl-3!
      `};
`;

export const StyledNavBarDropdownGroup = styled.div`

  relative
  > div {
    flex
  }

  ${tw`
    mx-4 md:mx-0 mt-0
    shadow-md md:shadow-none
    block
    bg-ultralightgreen md:bg-white
     flex-grow
  `}




  :hover{
    > div {
      ${tw`
        md:flex
      `}
    }
  } 

`;

export const Navbardropdown = styled.div`
 
  ${tw`
    flex 
    flex-row
    flex-wrap
    md:w-12
    md:flex-col
    bg-gray-100
    md:hidden
    md:absolute 
    md:shadow-lg
    md:w-auto
    md:h-auto
    z-50
  `}

  ${props =>
			props.footer &&
			tw`
    transform  -translate-y-full bg-gray-500 -mt-10 w-56!
  `}

  & a {
    ${tw`
      md:p-4
      w-full
    `}
`;

export const NavbarMenu = tw.div`
    hidden
    md:flex
    w-2/5  flex-grow lg:flex content-center items-center lg:w-2/5
    px-0
    mx-0
    pb-12
    bg-white
    flex-row
    justify-between
    `;

export const DropdownIcon = styled(Chevron)`
	${tw`
      hidden
      md:block
      
      ml-2 
      h-5
      fill-current
      text-tbrteal


    `}
`;
