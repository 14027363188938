import React from 'react';
import { StyledPhone } from './Phone.styles';
import phoneIcon from '../../img/phone.svg';

const Phone = ({ className }) => (
	<StyledPhone
		className={className}
		src={phoneIcon}
		alt="Phone"
		width={24}
		height={24}></StyledPhone>
);
export default Phone;
