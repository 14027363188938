import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import WhatsappIcon from '../whatsapp/WhatsappIcon.react';
import Phone from '../Phone/Phone.react';
export const StyledContactBar = styled.div`
	${tw`
  flex 
  flex-col
  md:flex-row
  mx-8
  bg-white
  md:space-x-4
  `}
`;

export const StyledContactBarButton = styled.a`
	${tw`
  flex
  hover:bg-blue-100
  items-center
  p-3

  `}
`;

export const TextHide = styled.span`
	${props => props.short && tw`hidden`}
`;

export const ContactBarWhatsAppIcon = styled(WhatsappIcon)`
	${tw`
    w-8 mr-2!
  `}
	${props => props.short && tw`w-6`}
`;

export const ContactBarPhoneIcon = styled(Phone)`
	${tw`
    w-6 mr-2!
  `}
	${props => props.short && tw`w-5`}
`;

export const StyledContactBarText = styled.div`
	${tw`
font-serif text-xl md:text-2xl text-tbrteal
 
  `}
`;

export const StyledContactBarPhone = styled.div`
	${tw`
   text-xl md:text-2xl
  `}

	${props => props.short && tw`hidden`}
`;
