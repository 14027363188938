import styled from 'styled-components';
import tw from 'twin.macro';
import { Heading2 } from '../../Typography/Typography.react';
import Button from '../../Blocks/Button/Button.react';

export const StyledCard = styled.div`
	${tw`
						bg-tbrteal
						flex
						flex-col
						items-center
						p-4 md:p-8		
						font-serif text-xl
						
		`}
`;

export const CardHeading = styled(Heading2)`
	${tw`
	mt-0
	text-2xl md:text-3xl
		`}
`;

export const CardButton = styled(Button)`
	${tw`text-xl md:text-2xl	`}
`;
