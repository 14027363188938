export const HERO_FRAMED_BLOCK_ATTRIBUTES = {
	VARIANT: 'hero',
	FRAME: true,
};

export const HERO_BLOCK_ATTRIBUTES = {
	VARIANT: 'hero',
	FRAME: true,
};

export const PORTFOLIO_BLOCK_ATTRIBUTES = {
	VARIANT: 'hero-no-frame',
	FRAME: false,
};

export const LARGE_IMAGE_ATTRIBUTES = {
	SUFFIX: '?w=1200&h=1000&fit=crop',
	RATIO: '3/4',
	SIZE: 'full',
	POSITION: null,
};

export const SMALL_IMAGE_ATTRIBUTES = {
	SUFFIX: '?w=400&h=900&fit=crop',
	RATIO: '3/4',
	SIZE: 'small',
	POSITION: 'top-left',
};

export const PORTFOLIO_PAGE_ATTRIBUTES = {
	HEROBLOCK: PORTFOLIO_BLOCK_ATTRIBUTES,
	FRAME: true,
	BLOCKS: {
		FRAME: false,
	},
	NEXT_PAGE: {
		TITLE: 'Next Page',
		BUTTON: {
			NEXT_PAGE_LINK: 'portfolio',
			NEXT_PAGE_BUTTON_TEXT: 'Portfolio',
		},
		IMAGE: {
			IMAGE:
				'https://res.cloudinary.com/tbr-floral-design/image/upload/c_fill,h_800,w_800/v1604587301/portfolio/MBP-135.jpg',
			ALT: 'This is the Alt Text',
		},
	},
};

export const HOME_PAGE_ATTRIBUTES = {
	HEROBLOCK: HERO_FRAMED_BLOCK_ATTRIBUTES,
	FRAME: false,
	BLOCKS: {
		FRAME: true,
	},
	NEXT_PAGE: {
		TITLE: 'Next Page',
		BUTTON: {
			NEXT_PAGE_LINK: 'portfolio',
			NEXT_PAGE_BUTTON_TEXT: 'Portfolio',
		},
		IMAGE: {
			IMAGE:
				'https://res.cloudinary.com/tbr-floral-design/image/upload/c_fill,h_800,w_800/v1604587301/portfolio/MBP-135.jpg',
			ALT: 'This is sdafasd asdfsadf Alt Text',
		},
	},
};

export const largeBaseImageSize = '?w=1400&h=1000&fit=crop';
export const smallBaseImageSize = '?w=400&h=900&fit=crop';

// take an image object and attibuters and create the object to be in the hero block
const heroObjectMaker = (obj, imageAttributes) => {
	const { SUFFIX, RATIO, SIZE, POSITION } = imageAttributes;
	let alt = '';
	if (obj.alt !== null || obj.alt !== undefined) {
		alt = obj.alt;
	}
	const image = {};
	if (obj.file.url !== null) {
		image.image = obj.fluid;
		image.id = obj.id;
		image.title = obj.title;
		image.ratio = RATIO;
		image.size = SIZE;
		image.alt = alt;

		if (POSITION) {
			image.position = POSITION;
		}
		return image;
	}
	return null;
};

export const heroImagesFlattener = (firstImage, secondImage, imagesArray) => {
	// start with an empty array for images
	// will fill it up with the image objects once sanitased as per our usage
	// export const images = [];
	// tale an object from an image and the ratio, size atlt text and position
	// and return an object to be added to thge Hero Images Array

	if (firstImage !== null) {
		const image1 = heroObjectMaker(firstImage, LARGE_IMAGE_ATTRIBUTES);
		imagesArray.push(image1);
	}
	if (secondImage !== null) {
		const image2 = heroObjectMaker(secondImage, SMALL_IMAGE_ATTRIBUTES);
		image2.position = 'top-left';
		imagesArray.push(image2);
	}
	return imagesArray;
};

export const rawBlocksMaker = (rawBlocksArray, frameBolean) => {
	const madeBlocks = rawBlocksArray.map(
		({
			cardBody,
			rawImages,
			mainBlockImage,
			secondaryBlockImage,
			secondaryBlockImagePosition,
			buttonLink,
			buttonText,
			...otherRawBlock
		}) => {
			const body = cardBody.childMarkdownRemark.html;
			const button = { buttonLink, buttonText };
			const mainBlock = {
				fluid: mainBlockImage.fluid,
				id: mainBlockImage.id,
				alt: mainBlockImage.description,
				ratio: mainBlockImage.aspectRatio,
			};

			const images = [mainBlock];

			if (secondaryBlockImage !== null) {
				const secondaryBlock = {
					fluid: secondaryBlockImage.fluid,
					id: secondaryBlockImage.id,
					alt: secondaryBlockImage.description,
					ratio: secondaryBlockImage.aspectRatio,
					position: secondaryBlockImagePosition,
				};

				images.push(secondaryBlock);
			}

			const card = { body, button };

			return {
				card,
				images,
				frame: !frameBolean,
				...otherRawBlock,
			};
		},
	);
	return madeBlocks;
};

// takes the rawGallery array and parses it to give us a cleaner object
export const rawGalleryMaker = rawGalleryArray => {
	const singleGalleryMaker = galObj => {};

	console.log(rawGalleryArray);
	const galleries = rawGalleryArray.map(
		({ description, media, id, row1, row2, row3, row4, row5, galleryTitle }) => {
			const finalRows = [];
			const checkRows = rowN => {
				if (rowN !== null || rowN) {
					finalRows.push(rowN);
				}
				// console.log('------------------------------------------')
				// console.log(finalRows)
				// console.log('------------------------------------------')
			};
			checkRows(row1);
			checkRows(row2);
			checkRows(row3);
			checkRows(row4);
			checkRows(row5);

			const gallery = media.map(({ media, videoUrl, externalUrl }, i) => {
				const uniqueID = media.id + i + id;
				const galleryItem = {
					fluid: media.fluid,
					alt: media.title,
					title: media.title,
					id: uniqueID,
					ratio: media.fluid.aspectRatio,
					span: 2,
					videoUrl: videoUrl,
					thumbnail: media.fluid.src,
					externalUrl: externalUrl,
				};
				return galleryItem;
			});
			return {
				id: id,
				title: galleryTitle,
				description: description?.childMarkdownRemark.html,
				gallery,
				rows: [...finalRows],
			};
		},
	);
	return galleries;
};

// Take
export const pageObjectMaker = (
	pageAttributes,
	heroObj,
	secondaryContentObj,
	blocksArray,
	nextPrevObj,
	frameBolean,
) => {
	let hero = heroObj;
	let blocks = blocksArray;
	let nextPrev = nextPrevObj;
	let secondaryContent = secondaryContentObj;
	return {
		frame: frameBolean,
		hero,
		blocks,
		nextPrev,
		secondaryContent,
	};
};

// Take
export const galleryPageObjectMaker = (
	pageAttributes,
	secondaryContentObj,
	heroObj,
	galleryArray,
	nextPrevObj,
	frameBolean,
	subNavOjb,
) => {
	let secondaryContent = secondaryContentObj;
	let hero = heroObj;
	let galleries = galleryArray;
	let nextPrev = nextPrevObj;
	let subnav = subNavOjb;
	return {
		frame: frameBolean,
		hero,
		galleries,
		nextPrev,
		secondaryContent,
		subnav: subNavOjb,
	};
};

const secondaryObjectMaker = (secTitle, secBlock, secCols, secPos) => {
	let secondaryContent = null;
	let position = null;
	if (secBlock !== null) {
		secondaryContent = {
			title: secTitle,
			body: secBlock.childMarkdownRemark.html,
			columns: secCols,
			position: secPos,
		};
	}
	return secondaryContent;
};

// get the object from the prev or next and clean it up into an object to be used
const nextPrevObjectMaker = obj => {
	return {
		button: {
			buttonLink: '/' + obj.slug,
			buttonText: obj.name,
		},
		title: obj.title,
		image: {
			fluid: obj.heroImage.fluid,
			alt: obj.heroImage.description,
			id: obj.id,
		},
	};
};

export const pageProcessor = (queryData, pageAttributes) => {
	const {
		id,
		name,
		heroImage,
		heroSecondaryImage,
		title,
		alt,
		blocks: rawBlocks, //alias as rawblocks as to not clash with exported blocks
		heroText,
		previousPage,
		nextPage,
		heroSecondaryPosition,
		frame,
		secondaryPageBlock,
		secondaryBlockTitle,
		secondaryBlockColumns,
	} = queryData;

	const secondaryContent = secondaryObjectMaker(
		secondaryBlockTitle,
		secondaryPageBlock,
		secondaryBlockColumns,
		null,
	);
	// let secondaryContent = null

	// if (secondaryBlockTitle !== null) {
	// 	secondaryContent = {
	// 		title: secondaryBlockTitle,
	// 		body: secondaryPageBlock.childMarkdownRemark.html,
	// 		columns: secondaryBlockColumns
	// 	}
	// }

	const images = [];
	heroImagesFlattener(heroImage, heroSecondaryImage, images);

	const hero = {
		id,
		name,
		images,
		title,
		variant: pageAttributes.HEROBLOCK.VARIANT,
		frame: !frame,
		card: { body: heroText.childMarkdownRemark.html },
	};

	if (images[1]) {
		images[1].position = heroSecondaryPosition;
	}
	const nextPrev = {};

	// if (nextPage !== null) {
	// 	nextPrev.next = nextPrevObjectMaker(nextPage);
	// }
	// if (previousPage !== null) {
	// 	nextPrev.prev = nextPrevObjectMaker(previousPage);
	// }

	let blocks = null;

	if (rawBlocks !== null) {
		blocks = rawBlocksMaker(rawBlocks, frame);
	}

	return pageObjectMaker(pageAttributes, hero, secondaryContent, blocks, nextPrev, frame);
};

export const galleryPageProcessor = (queryData, pageAttributes) => {
	const {
		id,
		name,
		lightbox,
		heroImage,
		heroSecondaryImage,
		heroSecondaryPosition,
		title,
		alt,
		galleries: rawGalleries, //alias as rawblocks as to not clash with exported blocks
		heroText,
		previousPage,
		nextPage,
		secondaryBlockTitle,
		secondaryBlockPosition,
		secondaryTextBlock,
		secondaryBlockColumns,
	} = queryData;

	const secondaryContent = secondaryObjectMaker(
		secondaryBlockTitle,
		secondaryTextBlock,
		secondaryBlockColumns,
		secondaryBlockPosition,
	);

	const frame = true;

	const nextPrev = {};

	// if (nextPage !== null) {
	// 	nextPrev.next = nextPrevObjectMaker(nextPage);
	// }
	// if (previousPage !== null) {
	// 	nextPrev.prev = nextPrevObjectMaker(previousPage);
	// }

	const images = [];
	heroImagesFlattener(heroImage, heroSecondaryImage, images);

	if (images[1]) {
		images[1].position = heroSecondaryPosition;
	}

	// build the hero object
	const hero = {
		id,
		name,
		images,
		title,
		variant: pageAttributes.HEROBLOCK.VARIANT,
		frame: !frame,
		card: { body: heroText.childMarkdownRemark.html },
	};

	const galleries = rawGalleryMaker(rawGalleries);

	const subnavObj = galleries.map(({ title, id }) => {
		const titleUrl =
			'#' +
			title
				.toLowerCase()
				.replace(/ /g, '-')
				.replace(/[^\w-]+/g, '');

		return {
			buttonLink: titleUrl,
			buttonText: title,
		};
	});

	const subnav = {
		title: title,
		body: '',
		subnav: subnavObj,
	};

	return galleryPageObjectMaker(
		pageAttributes,
		secondaryContent,
		hero,
		galleries,
		nextPrev,
		frame,
		subnav,
	);
};
