import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';

const HeadingHero = tw.h1`
text-4xl text-tbrgray

 text-tbrgray

 font-bold
`;

const Heading2 = tw.h2`
text-2xl 
text-tbrgray

font-serif
`;

const Heading3 = tw.h3`
text-xl text-tbrgray
 
font-serif
`;

const Heading4 = tw.h4`
text-lg text-tbrgray  
font-serif
`;

const BodyText = tw.p`
text-xl text-tbrgray my-4
`;

const IntroText = tw.p`
text-xl text-tbrgray my-4
`;

export { HeadingHero, Heading2, Heading3, Heading4, IntroText, BodyText };
