import React from 'react';
import { StyledLogo, StyledLink } from './Logo.styles';
import TBRlogo from '../../img/tbr-logo.svg';

const Logo = ({ className, ...props }) => (
	<StyledLink to={'/index'}>
		<StyledLogo src={TBRlogo} alt="Logo" width={320} height={260} className={className} {...props} />
	</StyledLink>
);

export default Logo;
