import React from 'react';
import {
	StyledGalleryBlock,
	GalleryHeading,
	GalleryDescription,
	Gallery,
	GalleryItem,
	StyledGalleryRow,
} from './GalleryBlock.styles';
import { SRLWrapper } from 'simple-react-lightbox-pro';
import SimpleReactLightbox from 'simple-react-lightbox-pro';
import { setRows, rowLogicalWidth, widthPercentage, lightBoxOptions } from './GalleryBlock.utils';

const GalleryRows = ({ rows, gallery, className, title, ...otherProps }) => {
	//get new array of rows from the gallery
	const rowsOutput = setRows(rows, gallery);
	//get the widths from each row from the rowsOutput array
	const rowsWidths = rowsOutput.map(row => rowLogicalWidth(row));

	return rowsOutput.map((row, i) => (
		<StyledGalleryRow
			rowLogicalWidth={rowsWidths[i]}
			key={title + '-row-' + i}
			set={rows[i]}
			{...otherProps}>
			{row.map((item, ii) => (
				<GalleryItem
					itemWidth={widthPercentage(rowsWidths[i], item.ratio)}
					{...item}
					key={item.id}
					className={className}
				/>
			))}
		</StyledGalleryRow>
	));
};

const GalleryBlock = ({
	rows,
	title,
	description,
	gallery,
	className,
	lightbox,
	...otherProps
}) => {
	return (
		<StyledGalleryBlock>
			<GalleryHeading
				children={title}
				id={title
					.toLowerCase()
					.replace(/ /g, '-')
					.replace(/[^\w-]+/g, '')}
			/>
			{description !== undefined && (
				<GalleryDescription
					dangerouslySetInnerHTML={{
						__html: description,
					}}
				/>
			)}
			{lightbox === true ? (
				<SimpleReactLightbox>
					<SRLWrapper options={lightBoxOptions}>
						<GalleryRows gallery={gallery} title={title} rows={rows} className={className} />
					</SRLWrapper>
				</SimpleReactLightbox>
			) : (
				<Gallery>
					<GalleryRows gallery={gallery} title={title} rows={rows} className={className} />
				</Gallery>
			)}
		</StyledGalleryBlock>
	);
};

export default GalleryBlock;
