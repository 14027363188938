import style from 'styled-components';
import tw from 'twin.macro';
import ImageHolder from '../ImageHolder/ImageHolder.react';
import { Heading2 } from '../../Typography/Typography.react';

export const StyledGalleryBlock = style.div`
  ${tw`
			py-4 md:py-8 
			mx-auto md:ml-1/12
			w-7/8 md:w-10/12
  `}
`;

export const GalleryHeading = style(Heading2)`
  ${tw`
    pb-4
  `}
`;

export const GalleryDescription = style.div`
  ${tw`
    pb-4
  `}
`;
export const Gallery = style.div`
  ${tw`
  
  `}
`;
export const GalleryItem = style(ImageHolder)`
  ${tw`
    self-start 
    cursor-pointer
  `}

  width:${props => props.itemWidth}
`;

export const StyledGalleryRow = style.div`
${tw`
      flex flex-no-wrap justify-items-center
      space-x-2 md:space-x-4
      pb-4
    `}

`;
