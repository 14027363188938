import styled from 'styled-components';
import tw from 'twin.macro';

const StyledFrame = styled.div`
	${tw`
    block
    absolute
    border-tbrteal
 		border-solid border-6
		top-0  bottom-0 	 
		w-full md:w-5/6 
		z-0		
`}

	z-index:0;
`;

export default StyledFrame;
