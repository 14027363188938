import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import Frame from '../Frame/Frame.react';

export const StyledPageBlock = styled.div`
	${tw`
        w-screen
        md:max-w-screen-md
        lg:max-w-screen-lg
        relative
        mx-auto
        my-12
        h-full
        overflow-x-hidden
        flex
        flex-col
        
  `}
	${props => props.variant !== 'secondary' && tw`md:w-11/12`}
`;

export const PageFrame = styled.div`
	${tw`
    block
    absolute
    border-tbrteal
    border-solid border-5
    top-0  bottom-0 
    
  	inset-x-2 md:inset-x-10
		z-0		
`}
`;
