import styled from 'styled-components';
import tw, { css } from 'twin.macro';
import { Link } from 'gatsby';
import { HeaderLogo } from '../../Header/Header.styles';
export const StyledLogo = styled.img`
	${tw`
  
    h-full
    w-full
    relative
  `}
`;

export const StyledLink = styled(Link)`
	${tw`
    inline
    mx-auto
    w-full
  `}

	${HeaderLogo} {
		width: 50%;
	}
`;
