import React from 'react';
import { PageFrame, StyledPageBlock } from './PageBlock.styles';

const PageBlock = ({ children, className, frame, ...props }) => (
	<StyledPageBlock {...props} frame={frame} className={className}>
		{children}
		{frame === true && <PageFrame className={className} />}
	</StyledPageBlock>
);

export default PageBlock;
