import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { StyledCard, CardHeading, CardButton } from './Card.styles';

const Card = ({ title, body, button, className, ...otherCardProps }) => (
	<StyledCard {...otherCardProps} className={className}>
		{title !== undefined && (
			<CardHeading className={'className'} as="h2">
				{title}{' '}
			</CardHeading>
		)}
		{body !== undefined && (
			<div
				dangerouslySetInnerHTML={{
					__html: body,
				}}></div>
		)}
		{button !== undefined && <CardButton {...button} />}
	</StyledCard>
);

Card.propTypes = {
	variant: PropTypes.oneOf([
		'hero',
		'secondary',
		'gallery-hero',
		'hero-no-image',
		'hero-no-frame',
		'gallery',
	]),
	title: PropTypes.string,
	body: PropTypes.string,
	buttonLink: PropTypes.string,
	buttonText: PropTypes.string,
	side: PropTypes.oneOf(['left', 'right']),
};

export default Card;
