import styled from 'styled-components';
import tw, { css } from 'twin.macro';

export const StyledBurger = styled.a`
	cursor: pointer;
	display: block;
	height: 3.25rem;
	width: 3.25rem;
	// margin-left: auto;

	${tw`
    relative
     mr-4
     flex-grow-0
		  bg-ultralightgreen

`}
	span {
		${tw`
        bg-tbrteal

        hover:bg-tbrteal

    `}
		display: block;
		height: 1px;
		left: calc(50% - 8px);
		position: absolute;
		transform-origin: center;
		transition-duration: 86ms;
		transition-property: background-color, opacity, transform;
		transition-timing-function: ease-out;
		width: 16px;
	}

	span:nth-child(1) {
		top: calc(50% - 6px);
	}

	span:nth-child(2) {
		top: calc(50% - 1px);
	}

	span:nth-child(3) {
		top: calc(50% + 4px);
	}

	:hover {
		${tw`
        bg-leaf
        shadow-md
    `}
	}

	${props =>
		props.open
			? `
         
        span:nth-child(1) {
            transform: translateY(5px) rotate(45deg);
        }
        span:nth-child(2) {
            opacity: 0;
        }
        span:nth-child(3) {
            transform: translateY(-5px) rotate(-45deg);
        }

        ${tw`
        bg-gray-200
        shadow-md
        `}


        `
			: `
        span:nth-child(1) {
            top: calc(50% - 6px);
        }
        
        span:nth-child(2) {
            top: calc(50% - 1px);
        }
        
        span:nth-child(3) {
            top: calc(50% + 4px);
        }
        
    `}
`;
