import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import Img from 'gatsby-image';

export const StyledImageHolder = styled.div`
	${tw`
		right-0
		text-right
		relative
		z-20
	`}
`;

export const ImageRatioSetter = styled.div`
	display: block;
	width: 100 %;
	${props =>
		props.ratio === '3/4'
			? tw`
				pb-3/4
			`
			: props.ratio === '4/3'
			? tw`
				pb-4/3
				`
			: props.ratio === '1/1'
			? tw`	pb-1/1
				`
			: ''}
`;

export const HeldImage = styled(Img)`
	${tw`
	w-full
	h-full
	absolute
	inset-0
	object-cover
`}
`;
