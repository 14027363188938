import styled, { css } from 'styled-components';
import tw from 'twin.macro';
import Card from '../Card/Card.react';
import ImageHolder from '../ImageHolder/ImageHolder.react';

export const ContentBlockWrapper = styled.div`
	${tw`
  bg-no-repeat
  bg-cover
	relative
	block
	lg:max-w-screen-lg
	z-10
	`}

	${props =>
		props.frame === true &&
		tw`
			pb-8 md:pb-16
			mb-8 md:mb-16
	`}

	${props =>
		(props.variant === 'hero-no-frame' || props.variant === 'hero-no-image') &&
		tw`
			py-4 md:py-8 
			mx-auto md:ml-1/12
			w-7/8 md:w-10/12
		`}


	${props =>
		props.variant === 'gallery' &&
		tw`
			py-16 md:py-8 
			w-7/8 md:w-11/12
		`}

	${props =>
		props.variant === 'gallery' &&
		props.side === 'right' &&
		tw`
			
		`}

	${props =>
		props.variant === 'gallery' &&
		props.side === 'left' &&
		tw`
			ml-1/8 md:ml-1/12
		`}
`;

export const StyledBlockSection = styled.div`
	${tw`
		text-2xl 
		leading-none
	`}

	${props => props.section === undefined && tw`hidden`}
`;

export const BlockHeading = styled.h2`
	${tw`
    text-tbrgray
 		font-serif text-3xl md:text-4xl
		w-full md:w-5/6
		py-5  pb-12 md:pb-10
		relative z-30
		tracking-tight
`}

	${props =>
		(props.variant === 'hero' ||
			props.variant === 'hero-no-frame' ||
			props.variant === 'hero-no-image') &&
		tw`
			text-tbrgray
      text-5xl md:text-6xl
      leading-12 md:leading-16
		`}

	${props =>
		(props.variant === 'hero' || props.variant === 'hero-no-image') &&
		tw`
			float-right text-right
			w-5/6 md:w-3/5	mr-5 md:mr-1/12 pt-5 pb-5 md:pt-8 md:pb-10		
		`}
	${props => props.variant === 'hero' && tw`mr-1/12 pb-8 md:pb-4`}
	${props => props.variant === 'secondary' && tw``}
	${props => props.variant === 'hero-no-frame' && tw`ml-0 text-left float-left`}
	${props => props.side === 'right' && tw`ml-3 md:ml-3/12`}
	${props => props.side === 'left' && tw`	ml-1/8 md:ml-1/12`}
`;

export const BlockImageHolder = styled(ImageHolder)`
	${tw`
		right-0
		text-right
		relative
		z-20
	`}
	// based on size
${props => props.size === 'full' && tw`		left-0	w-11/12 inline-block`}

${props => props.size === 'small' && tw`absolute inline z-30 w-1/4 md:w-24 lg:w-36  `}

${props => props.side === 'right' && props.size === 'full' && tw`w-11/12 mr-6 md:mr-16`}
${props => props.side === 'left' && props.size === 'full' && tw` w-11/12 ml-1/12`}

${props =>
		props.size === 'small' && props.position === 'top-left' && tw` left-1/12 md:left-1/6 -mt-8`}

${props =>
		props.size === 'small' &&
		props.side === 'right' &&
		props.position === 'top-left' &&
		tw` left-1/12 md:left-1/6 -mt-8`}

		
// syle the small image based on the side variable
${props =>
		props.size === 'small' &&
		props.side === false &&
		props.position === undefined &&
		tw`
		absolute	top-24 md:top-32  mr-1/8 md:mr-24	`}

${props =>
		props.side === 'right' && props.position === 'top-right' && tw`	top-20 md:top-8  mr-1/8 md:mr-4	`}

${props =>
		props.side === 'left' &&
		props.position === 'top-right' &&
		tw`	top-20 md:top-8 mr-1/16  md:mr-3/12`}

${props =>
		props.variant === 'gallery' &&
		tw`
			w-11/12 flex-none! flex-grow-0
			`}
`;

export const BlockCard = styled(Card)`
	${tw`
		-mt-12 md:-mt-24 mb-4
		relative z-30
		md:right-0
		bg-leaf

		`}

	${props => props.variant !== 'secondary' && tw`  font-serif text-xl md:text-2xl md:leading-4`}

	${props => props.variant === 'secondary' && tw` w-3/4 md:w-1/2`}

	${props => props.variant === 'hero' && tw` md:ml-1/6 w-5/6 md:w-3/4 md:leading-12`}

${props =>
		props.variant === 'hero-no-frame' &&
		tw`mx-auto w-full w-5/8 md:w-3/4 text-xl md:text-2xl md:leading-8 `}

${props =>
		props.variant === 'hero-no-image' &&
		tw` mx-auto w-full  mt-0 md:mt-0 text-xl md:text-2xl md:leading-8`}

${props => props.side === 'left' && props.variant === 'secondary' && tw`	mx-auto md:ml-2/12	`}

${props => props.side === 'right' && props.variant === 'secondary' && tw` mx-auto md:ml-3/12`}

// gallery variant
${props =>
		props.variant === 'gallery' &&
		tw`w-5/8 md:w-4/12
			md:my-0
			absolute
			z-30
			mt-0
			md:top-1/2
			transform -translate-y-1/2		 
			`}

${props =>
		props.variant === 'gallery' &&
		props.side === 'right' &&
		tw`
			right-0
		`}

${props =>
		props.variant === 'gallery' &&
		props.side === 'left' &&
		tw`
		left-0
		`}
`;

export const BlockFrame = styled.div`
	${tw`
    block
    absolute
    border-tbrteal
 		border-solid 
		border-5
		top-0  bottom-0 	 
		
		z-0		
	`}

	${props => props.variant === 'hero' && tw`w-full md:w-11/12`}

${props => props.variant === 'secondary' && tw`w-full md:w-5/6`}

${props => props.side === 'right' && tw`right-3 md:right-0`}

${props => props.side === 'left' && tw`left-3 md:left-0 pl-3`}
`;
