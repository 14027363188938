import React from 'react';
import {
	StyledHeader,
	Navbar,
	NavbarMobile,
	NavbarMenu,
	DropdownIcon,
	StyledMainMenu,
	Navbardropdown,
	NavbarItem,
	StyledNavBarDropdownGroup,
	NavbarItemSub,
} from './MainMenu.styles';

const SubMenu = ({ mobile, subMenu, className, page, menuName, ...otherSubMenuProps }) => (
	<Navbardropdown className={className} expanded={mobile} {...otherSubMenuProps}>
		{subMenu.map(({ menuItemLink, menuItemText, icon, className, ...otherSubItemProps }, index) => (
			<NavbarItemSub
				to={'/' + menuItemLink}
				className={className}
				key={menuName + className + 'sub' + index}
				selected={page === menuItemLink && true}
				{...otherSubItemProps}>
				{menuItemText}
			</NavbarItemSub>
		))}
	</Navbardropdown>
);

const NavBarDropdownGroup = ({
	menuItemLink,
	menuItemText,
	subMenu,
	className,
	page,
	...otherProps
}) => (
	<StyledNavBarDropdownGroup className={className} {...otherProps}>
		<NavbarItem to={'/' + menuItemLink} selected={page === menuItemLink && true}>
			{menuItemText}
			<DropdownIcon fill="red" />
		</NavbarItem>
		<SubMenu subMenu={subMenu} className={className} {...otherProps} page={page} />
	</StyledNavBarDropdownGroup>
);

const MainMenu = ({ mobile, menu, className, icon, page, menuName, ...otherProps }) => (
	<StyledMainMenu className={className} mobile={mobile} {...otherProps}>
		{menu.map(({ menuItemLink, menuItemText, subMenu, ...otherItemProps }, index) =>
			subMenu === undefined ? (
				<NavbarItem
					to={'/' + menuItemLink}
					children={menuItemText}
					selected={page === menuItemLink && true}
					key={menuName + className + index}
					{...otherItemProps}
				/>
			) : (
				<NavBarDropdownGroup
					menuName={menuName}
					key={menuName + 'portfolio' + index}
					page={page}
					menuItemLink={menuItemLink}
					menuItemText={menuItemText}
					subMenu={subMenu}
					mobile={mobile}
					{...otherProps}
				/>
			),
		)}
	</StyledMainMenu>
);

export default MainMenu;
