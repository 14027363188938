import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import {
	ContentBlockWrapper,
	BlockImageHolder,
	BlockCard,
	BlockFrame,
	BlockHeading,
	StyledBlockSection,
} from './ContentBlock.styles';

const BlockSection = ({ className, section, children }) => (
	<StyledBlockSection className={className} section={section}>
		{section}
	</StyledBlockSection>
);

const ContentBlock = ({ images, title, section, variant, side, frame, card }) => {
	const blockVariant = () => {
		if (images.length === 0) {
			return 'hero-no-image';
		}
		return variant;
	};
	return (
		<ContentBlockWrapper variant={blockVariant()} side={side} frame={frame}>
			{title !== undefined && variant !== 'gallery' && (
				<BlockHeading side={side} variant={variant} as={variant !== 'secondary' && 'h1'}>
					<BlockSection section={section} />
					{title}
				</BlockHeading>
			)}

			{images !== null &&
				images.map(({ id, size, ...otherImageProps }, index) => (
					<BlockImageHolder
						side={side}
						key={id + index}
						size={index === 0 ? 'full' : 'small'}
						{...otherImageProps}
					/>
				))}

			{frame === true && <BlockFrame variant={variant} side={side} />}
			<BlockCard {...card} variant={blockVariant()} side={side} />
		</ContentBlockWrapper>
	);
};

// ContentBlock.propTypes = {
// 	variant: PropTypes.oneOf([
// 		'hero',
// 		'secondary',
// 		'gallery-hero',
// 		'hero-no-image',
// 		'hero-no-frame',
// 		'gallery',
// 	]).isRequired,
// 	frame: PropTypes.bool,
// 	images: PropTypes.node,
// 	title: PropTypes.string.isRequired,
// 	side: PropTypes.oneOf(['left', 'right', null]),
// };

export default ContentBlock;
