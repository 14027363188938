import React from 'react';
import { StyledButton } from './Button.styles';
import PropTypes from 'prop-types';

const Button = ({ symbol, variant, buttonLink, buttonText, className }) => (
	<StyledButton className={className} to={buttonLink} variant={variant}>
		{symbol}
		{buttonText}
	</StyledButton>
);

export default Button;
