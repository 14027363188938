import React from 'react';

import {
	StyledContactBar,
	StyledContactBarButton,
	TextHide,
	ContactBarWhatsAppIcon,
	ContactBarPhoneIcon,
	StyledContactBarText,
	StyledContactBarPhone,
} from './ContactBar.styles';

export const WhatsAppButton = ({ className, ...otherProps }) => (
	<StyledContactBarButton className={className} {...otherProps} href="https://wa.me/+447977494085">
		<ContactBarWhatsAppIcon className={className} {...otherProps} />
		<StyledContactBarText className={className} {...otherProps}>
			<TextHide {...otherProps}>LET'S&nbsp;</TextHide>CHAT{' '}
			{/* <TextHide {...otherProps}>&nbsp;ON WHATSAPP</TextHide> */}
		</StyledContactBarText>
	</StyledContactBarButton>
);

export const PhoneButton = ({ className, ...otherProps }) => (
	<StyledContactBarButton className={className} href="tel:+442086584477">
		<ContactBarPhoneIcon className={className} {...otherProps} />
		<StyledContactBarText className={className}>CALL US</StyledContactBarText>
		<StyledContactBarPhone className={className} {...otherProps}>
			&nbsp;0208 658 4477
		</StyledContactBarPhone>
	</StyledContactBarButton>
);

const ContactBar = ({ className, ...otherProps }) => (
	<StyledContactBar className={className} {...otherProps}>
		<PhoneButton className={className} {...otherProps} />
		<WhatsAppButton className={className} {...otherProps} />
	</StyledContactBar>
);

export default ContactBar;
