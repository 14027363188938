import React, { useState } from 'react';
import { StyledBurger } from './Navburger.styles';

const NavBurger = ({ open, setOpen, className }) => {
	return (
		<StyledBurger open={open} onClick={() => setOpen(!open)} className={className}>
			<span />
			<span />
			<span />
		</StyledBurger>
	);
};

export default NavBurger;
