const tbrMenu = [
	{
		menuItemText: 'Home',
		menuItemLink: 'index',
	},
	{
		menuItemText: 'About',
		menuItemLink: 'about',
	},

	{
		menuItemText: 'Portfolio',
		menuItemLink: 'portfolio',
		subMenu: [
			{
				menuItemText: 'Weddings',
				menuItemLink: 'portfolio/weddings',
			},

			{
				menuItemText: 'Corporate Events',
				menuItemLink: 'portfolio/corporate-events',
			},
			{
				menuItemText: 'Dressed Venues',
				menuItemLink: 'portfolio/dressed-venues',
			},
			{
				menuItemText: 'Table Displays',
				menuItemLink: 'portfolio/table-displays',
			},
			{
				menuItemText: 'Pedestal Plinth Displays',
				menuItemLink: 'portfolio/pedestal-plinth-displays',
			},
			{
				menuItemText: 'Installations',
				menuItemLink: 'portfolio/installations',
			},
			{
				menuItemText: 'Candelabras',
				menuItemLink: 'portfolio/candelabras',
			},
			{
				menuItemText: 'Long Table Displays',
				menuItemLink: 'portfolio/long-table-displays',
			},
			{
				menuItemText: 'Long and Low Oasis Displays',
				menuItemLink: 'portfolio/long-and-low-oasis-displays',
			},
			{
				menuItemText: 'Grand Vases',
				menuItemLink: 'portfolio/grand-vases',
			},
			{
				menuItemText: 'Christmas',
				menuItemLink: 'portfolio/christmas',
			},
		],
	},
	{
		menuItemText: 'Press',
		menuItemLink: 'press',
	},
	{
		menuItemText: 'Videos',
		menuItemLink: 'videos',
	},
	{
		menuItemText: 'Venues',
		menuItemLink: 'venues',
	},
	{
		menuItemText: 'Clients',
		menuItemLink: 'clients',
	},
	{
		menuItemText: 'Contact',
		menuItemLink: 'contact',
	},
];

export default tbrMenu;
