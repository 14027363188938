import styled from 'styled-components';
import tw from 'twin.macro';
import { Link } from 'gatsby';

export const StyledButton = styled(Link)`
	${tw`
         text-tbrgray font-serif
         mt-2 md:mt-3
         p-2 md:p-4
         bg-palegreen
        
         text-center
         flex
         flex justify-center items-center
         leading-none
    `}

	&:hover {
		${tw`bg-ultralightgreen
 text-tbrgray`}
	}

	${props =>
		props.type === 'second'
			? tw`
        text-tbrteal

        bg-white
        border-solid border border-tbrteal

        `
			: ''}
`;
