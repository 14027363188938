import styled from 'styled-components';
import tw, { css } from 'twin.macro';
import ContactBar from '../Blocks/ContactBar/ContactBar.react';
import MainMenu from '../Blocks/MainMenu/MainMenu.react';

export const StyledHeader = styled.header`
	${tw` 
    mx-auto
     bg-white  
     w-full z-10 
    lg:max-w-screen-lg
    relative
    z-40
    md:flex
  `}
`;

export const HeaderLogoHolder = styled.div`
	${tw`
    mx-1/4 md:mx-auto lg:mx-2
    pt-16 md:pt-4 
     lg:px-2 
    flex-shrink
  
    md:w-1/4 lg:w-1/3
  `}
`;

export const HeaderContactBar = styled(ContactBar)`
	${tw`
    col-span-2
    mx-auto
    pt-3
    hidden md:flex
  `}
`;

export const HeaderNav = styled(MainMenu)`
	${tw`
    pt-4
     mx-auto
     w-full
     md:pt-24
     md:w-1/3
  `}
`;

export const NavbarMobile = styled(MainMenu)`
	${tw`
      lg:hidden! xl:hidden!
      hidden
      flex-col
      bg-white
      fixed
      inset-0
      w-screen
      h-screen
      z-50
      m-0 pt-8
      overflow-y-scroll
    `}

	${props => (props.open ? tw`flex` : tw`hidden`)}
`;

export const MobileControlBar = tw.div`
    bg-white
    bg-opacity-75
    md:hidden
    top-0 right-0
    w-full
    absolute
    fixed
    flex
    justify-end
    z-50
`;
