import React from 'react';
import { StyledWhatsappIcon } from './WhatsappIcon.styles';
import whatsappLogo from '../../img/whatsapp.svg';

const WhatsappIcon = ({ className }) => (
	<StyledWhatsappIcon
		className={className}
		src={whatsappLogo}
		alt="Logo"
		width={32}
		height={32}></StyledWhatsappIcon>
);

export default WhatsappIcon;
