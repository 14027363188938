import React from 'react';
import Header from '../Header/Header.react';
import PageBlock from '../Blocks/PageBlock/PageBlock.react';
// import Footer from '../Blocks/Footer/Footer.react';
import ContentBlock from '../Blocks/ContentBlock/ContentBlock.react';
import GalleryBlock from '../Blocks/GalleryBlock/GalleryBlock.react';
import { StyledPage } from './Page.styles';
import tbrMenu from '../../content/menu.js';
import loadable from '@loadable/component';

const SecondaryContentBlock = loadable(() =>
	import('../Blocks/SecondaryContentBlock/SecondaryContentBlock.react'),
);
const Footer = loadable(() => import('../Blocks/Footer/Footer.react'));
const SubnavBlock = loadable(() => import('../Blocks/SubnavBlock/SubnavBlock.react'));
const InstagramFeed = loadable(() => import('../Blocks/InstagramFeed/InstagramFeed.react'));

const Galleries = ({ galleries, lightbox }) =>
	galleries.map((gallery, index) => (
		<GalleryBlock {...gallery} key={gallery.id} lightbox={lightbox} />
	));

const Blocks = ({ blocks }) =>
	blocks.map((block, index) => {
		// set a const to
		const indexNum = index % 2;
		const sideArr = ['left', 'right'];
		return <ContentBlock key={index} side={sideArr[indexNum]} {...block} />;
	});

const PageContent = ({
	hero,
	blocks,
	secondaryContent,
	frame,
	subnav,
	galleries,
	nextPrev,
	lightbox,
	page,
	...otherProps
}) => (
	<>
		<PageBlock frame={frame}>
			<ContentBlock {...hero} side={!frame && 'right'} frame={hero.frame} />
			{subnav && subnav.length > 1 && <SubnavBlock {...subnav} />}
			{/* {galleries && !lightbox && <Galleries galleries={galleries} lightbox={lightbox} />} */}
			{secondaryContent && secondaryContent.position === 'top' && (
				<SecondaryContentBlock
					frame={frame}
					{...secondaryContent}
					position={secondaryContent.position}
				/>
			)}
			{galleries && <Galleries galleries={galleries} lightbox={lightbox} />}
			{blocks && <Blocks blocks={blocks} />}
			{secondaryContent && secondaryContent.position !== 'top' && (
				<SecondaryContentBlock frame={frame} page={page} {...secondaryContent} />
			)}
		</PageBlock>
		{/* <NextPrevious {...nextPrev} /> */}
	</>
);

const Page = ({ className, content, page, lightbox, instaFeed, ...otherProps }) => {
	return (
		<StyledPage className={className}>
			<Header menu={tbrMenu} page={page} />
			<PageContent {...content} lightbox={lightbox} page={page} />
			<InstagramFeed />
			<Footer menu={tbrMenu} page={page} footer={true} />
		</StyledPage>
	);
};

export default Page;
